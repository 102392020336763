import { apiService } from "../../services";
import {
  emailConstants,
  filterConstants,
  issuesConstants,
  modelConstants,
  projConstants,
} from "../constants";
import { labelType } from "../../components/enums/labelType";

export const projActions = {
  getProjects,
  openProject,
  returnProjects,
};

function getProjects(ids) {
  return (dispatch) => {
    apiService
      .getProjects(ids)
      .then((projects) => {
        dispatch(registerProjects(projects));
      })
      .catch((error) => {
        console.log("Can't get a project. ", error.message);
      });
  };

  function registerProjects(projects) {
    return {
      type: projConstants.PROJ_GETAll,
      payload: projects,
    };
  }
}

function openProject(project) {
  return (dispatch) => {
    apiService
      .getArrayOData(`label?$filter=type in ('${labelType.Tags}')`, [])
      .then((data) => {
        project.tags = data;
      })
      .catch((err) => {
        console.log("Can't get the tags in project.", err.message);
      });

    apiService
      .getArrayOData(`label?$filter=type in ('${labelType.WorkPhase}')`, [])
      .then((data) => {
        project.workPhases = data;
      })
      .catch((err) => {
        console.log("Can't get the WorkPhases in project.", err.message);
      });

    apiService
      .getData("collaborator-permissions")
      .then((res) => {
        project.actions = res.data.actions;
      })
      .catch((err) => {
        console.log("Can't get Collaborator Permissions", err.message);
      });

    apiService
      .getData("collaboratorgroup")
      .then((res) => {
        project.collaboratorGroups = res.data;
      })
      .catch((err) => {
        console.log("Can't get user groups", err.message);
      });

    apiService
      .getData("collaborator")
      .then((res) => {
        project.userPreviews = res.data;
      })
      .catch((err) => {
        console.log("Can't get Collaborators", err.message);
      });
    apiService
      .getDataByEndpoint(`inbox-email`)
      .then((res) => {
        dispatch(registerEmails(res.data));
      })
      .catch((err) => {
        console.log("Can't get the emails in project", err.message);
      });

    dispatch(registerProject(project));
  };

  function registerProject(project) {
    return {
      type: projConstants.PROJ_OPEN,
      payload: project,
    };
  }

  function registerEmails(emails) {
    return {
      type: emailConstants.GET_EMAIL,
      payload: emails,
    };
  }
}

function returnProjects(history) {
  return (dispatch) => {
    apiService.deleteDataByEndpoint("auth-project/leave").then((res) => {
      if (res.status === 200) {
        localStorage.removeItem("projectToken");
        dispatch(resetFilter());
        dispatch(resetIssues());
        dispatch(resetModels());
        history.push("/Projects");
        return {
          type: projConstants.PROJ_RETURN,
        };
      }
    });
  };

  function resetIssues() {
    return {
      type: issuesConstants.ISSUES_CLEAR,
    };
  }

  function resetFilter() {
    return {
      type: filterConstants.FILTER_RESET,
    };
  }

  function resetModels() {
    return {
      type: modelConstants.MODEL_CLEAR,
    };
  }
}
