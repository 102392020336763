import { viewpointActions } from '.';
import { apiService } from '../../services';
import { detailConstants, emailConstants, modelConstants, projConstants } from '../constants';
import { labelType } from "../../components/enums/labelType"

export const syncActions = {
    syncProject,
    syncIssue
}

function syncProject(projId) {
    return dispatch => {
        apiService.getArrayOData('project', [projId]).then(projects => {
            const project = projects[0];
            apiService.getArrayOData(`label?$filter=type in ('${labelType.Tags}')`, project.tagIds).then(data => {
                project.tags = data;
            });

            if (project.workPhaseIds) {
                apiService.getArrayData(`label?$filter=type in ('${labelType.WorkPhase}')`, project.workPhaseIds).then(workPhases => {
                    project.workPhases = workPhases;
                }).catch(err => {
                    console.log("Can't load the workphase in project.", err.message);
                });
            }

            dispatch(setCurrentProject(project));
            if (project.modelIds) {
                apiService.getArrayData('model', project.modelIds).then(models => {
                    dispatch(updateModels(models));
                })
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    function setCurrentProject(project) {
        return {
            type: projConstants.PROJ_OPEN,
            payload: project
        }
    }

    function updateModels(models) {
        let previewDtos = models.map(m => {
            return m.changedFile;
        });

        return {
            type: modelConstants.MODEL_GETALL,
            payload: previewDtos
        };
    }
}

function syncIssue(id) {
    return dispatch => {
        apiService.getArrayOData('issue', [id]).then(issues => {
            const issue = issues[0];
            if (issue.viewpointIds.length) {
                dispatch(viewpointActions.getViewpoint(issue.viewpointIds));
            }

            dispatch(updateIssue(issue));
        }).catch(err => {
            console.log(err);
        });
    }

    function updateIssue(issue) {
        return {
            type: detailConstants.DETAIL_SELECT,
            payload: issue
        }
    }
}